import React, { useState, useEffect } from "react";
import { apiCall } from "../../util/apiCall";
import "../../css/EditCollegeDetails.css";
import { Form } from "react-bootstrap";
import { FailureAlert } from "../../util/Notifications";
import { EditCollegeSection } from "./EditCollegeSection";
import TextField from "@material-ui/core/TextField";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { requestPraveshOptions } from "../../constants";
import AddNewCollegeStepper from "./AddNewCollegeStepper";
import { useLocation } from "react-router";

function EditCollegeDetails() {
  const [collegeId, setCollegeId] = useState(null);
  const [colleges, setColleges] = useState([]);

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("clgId"); //IdCollegeCourses
  const collegeName = new URLSearchParams(search).get("collegeName");
  const idCollegeCourses = new URLSearchParams(search).get("idCollegeCourses");
  // Fetch all Colleges Dropdown
  useEffect(() => {
    apiCall("/api/admin/college/get/", requestPraveshOptions).then((res) => {
      if (res.success) {
        let collegeNames = {};
        for (var i = 0; i < res.data.length; i++) {
          let obj = res.data[i];
          collegeNames[obj.college_id] = obj.college_name;
        }
        setColleges(res.data);
        localStorage.setItem("colleges", JSON.stringify(collegeNames));
      } else {
        FailureAlert(res.error);
      }
    });
  }, []);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  return (
    <div className="edit__college__details">
      {!id && (
        <Form className="edit__college__details__form">
          <Autocomplete
            id="select-user"
            options={colleges}
            filterOptions={filterOptions}
            getOptionLabel={(item) => item.college_name}
            style={{ width: "90%", margin: "5%" }}
            onChange={(e, item) =>
              item ? setCollegeId(item.college_id) : setCollegeId(null)
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select College"
                variant="outlined"
              />
            )}
          />
        </Form>
      )}
      {!collegeId ? null : (
        <AddNewCollegeStepper isEdit={true} clgId={collegeId} />
      )}

      {id && (
        <AddNewCollegeStepper
          isEdit={true}
          clgId={id}
          idCollegeCourses={idCollegeCourses}
          collegeName={collegeName}
        />
      )}
    </div>
  );
}

export default EditCollegeDetails;
