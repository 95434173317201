import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormLabel } from "react-bootstrap";
// import Checkbox from "@material-ui/core/Checkbox";
// import InputLabel from "@material-ui/core/InputLabel";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import NumberFormat from "react-number-format";
import gif from "../../assets/loopingcircles-3.gif";
import { gifStyling, requestPraveshOptions } from "../../constants";
import "../../css/AddCollege.css";
import "../../css/EducationalGroup.css";
import { apiCall } from "../../util/apiCall";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { characterCount } from "../../util/wordCounter";
import ColorPicker from "../common/ColorPicker";

function EditGroupSection({ grpId }) {
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const [countryId, setCountryId] = useState(1);
  const [stateId, setStateId] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [cityName, setCityName] = useState(null);
  const [stateName, setStateName] = useState(null);
  const [countryName, setCountryName] = useState(null);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [tpoFirstName, setTpoFirstName] = useState("");
  const [tpoLastName, setTpoLastName] = useState("");
  const [tpoMobile, setTpoMobile] = useState("");
  const [altTpoPhone, setAltTpoPhone] = useState("");
  const [tpoDesignation, setTpoDesignation] = useState("");
  const [tpoEmail, setTpoEmail] = useState("");
  const [altTpoEmail, setAltTpoEmail] = useState("");
  const [about, setAbout] = useState("");
  const [address, setAddress] = useState("");
  const [year, setYear] = useState("");
  const [url, setUrl] = useState("");
  const [isSaas, setIsSaas] = useState(false);
  const [paid, setPaid] = useState(true);
  const [paidDate, setPaidDate] = useState(null);
  const [active, setActive] = useState(true);
  const [logo, setLogo] = useState(null);
  const [circularLogo, setCircularLogo] = useState(null);
  const [favicon, setFavicon] = useState(null);
  const [banner, setBanner] = useState(null);
  const [fetchedLogo, setFetchedLogo] = useState(null);
  const [fetchedBanner, setFetchedBanner] = useState(null);
  const [sideImage, setSideImage] = useState(null);
  const [fetchedCircularLogo, setFetchedCircularLogo] = useState(null);
  const [fetchedFavicon, setFetchedFavicon] = useState(null);
  const [fetchedSideImage, setFetchedSideImage] = useState(null);
  const [primaryColor, setPrimaryColor] = useState("#007BFF");
  const [secondaryColor, setSecondaryColor] = useState("#e0a800");
  const [linkedIn, setLinkedIn] = useState("");
  const [facebook, setFacebook] = useState("");
  const [insta, setInsta] = useState("");
  const [trial, setTrial] = useState("");
  const [trialEnd, setTrialEnd] = useState("");
  const [payment, setPayment] = useState("");
  const [groupName, setGroupName] = useState(null);
  const [isDemoGiven, setDemoGiven] = useState(false);
  const [demoDate, setDemoDate] = useState(null);
  const [mouSignDate, setMouSignDate] = useState(null);
  const [invoiceSendDate, setInvoiceSendDate] = useState(null);
  const [collegeList, setCollegeList] = useState([]);
  const [totalAffiliatedCollege, setTotalAffiliatedCollege] = useState(null);
  const [allColleges, setAllColleges] = useState(null);

  const [getworkDomain, setGetworkDomain] = useState(null);

  const [aboutCount, setAboutCount] = useState(0);

  useEffect(() => {
    setLoading(true);
    apiCall(`/api/admin/education_group/view/?group_id=${grpId}`, requestPraveshOptions).then((result) => {
      setLoading(false);
      if (result.success) {
        let data = result.data;
        setGroupName(data.group_name);
        setTpoFirstName(data.tpo_details ? data.tpo_details.first_name : null);
        setTpoLastName(data.tpo_details ? data.tpo_details.last_name : null);
        setTpoMobile(data.tpo_details ? data.tpo_details.phone : null);
        setTpoEmail(data.tpo_details ? data.tpo_details.email : null);
        setAltTpoPhone(data.tpo_details ? data.tpo_details.alternate_phone : null);
        setAltTpoEmail(data.tpo_details ? data.tpo_details.alternate_email : null);
        setTpoDesignation(data.tpo_details?.job_title);
        setFetchedLogo(data.logo);
        setFetchedCircularLogo(data?.circular_logo);
        setFetchedFavicon(data?.favicon);
        setFetchedBanner(data.banner);
        setFetchedSideImage(data.side_image);
        setAbout(data.about);
        setYear(data.year_founded?.slice(0, 4));
        setUrl(data.website);
        setCountryId(data.country_id);
        setCountryName(data.country);
        setStateId(data.state_id);
        setStateName(data.state);
        setCityId(data.city_id);
        setCityName(data.city);
        setAddress(data.address);
        setPrimaryColor(data.primary_color);
        setSecondaryColor(data.secondary_color);
        setPaid(data.is_paid);
        setPaidDate(data.is_paid_date);
        setTrial(data.trial_period);
        setTrialEnd(data.trial_period_end);
        setPayment(data.amount);
        setDemoDate(data.demo_date);
        setDemoGiven(data.demo_given);
        setMouSignDate(data.mou_sign_date);
        setInvoiceSendDate(data.invoice_send_date);
        setGetworkDomain(data.domain);
        setIsSaas(data.is_saas);
        setActive(data.is_active);
        setFacebook(data.social_links?.map((item) => (item.name === "Facebook" ? item.value : null))[1]);
        setLinkedIn(data.social_links?.map((item) => (item.name === "LinkedIn" ? item.value : null))[0]);
        setInsta(data.social_links?.map((item) => (item.name === "Instagram" ? item.value : null))[2]);
        setTotalAffiliatedCollege(data.total_affiliated_college);
        setCollegeList(data.college_list ? data.college_list : []);
      } else {
        FailureAlert(result.error);
      }
    });
  }, [grpId]);

  const editGroup = (e) => {
    e.preventDefault();
    if (aboutCount > 512) {
      FailureAlert("College About can only have upto 512 characters...");
      return;
    }
    if (!groupName) {
      FailureAlert("Please Enter Group name...");
      return;
    }
    if (stateId === 0) {
      FailureAlert("Select State...");
      return;
    }
    if (cityId === 0) {
      FailureAlert("Select City...");
      return;
    }
    if (isSaas && !primaryColor) {
      FailureAlert("Choose Primary Color...");
      return;
    }
    if (isSaas && !secondaryColor) {
      FailureAlert("Upload Secondary Color...");
      return;
    }
    if (collegeList.length === 0) {
      FailureAlert("Select colleges..");
      return;
    }
    if (paid && !paidDate) {
      FailureAlert("Enter Payment date..");
      return;
    }

    const formData = new FormData();
    formData.append("person_first_name", tpoFirstName);
    formData.append("person_last_name", tpoLastName);
    formData.append("person_mobile", tpoMobile);
    altTpoPhone && formData.append("person_alternate_mobile", altTpoPhone);
    altTpoEmail && formData.append("person_alternate_email", altTpoEmail);
    formData.append("person_designation", tpoDesignation);
    formData.append("group_name", groupName);
    formData.append("about", about);
    formData.append("address", address);
    formData.append("year_founded", `${year}-01-01`);
    url && formData.append("website", url);
    logo && formData.append("logo", logo);
    favicon && formData.append("favicon", favicon);
    circularLogo && formData.append("circular_logo", circularLogo);

    banner && formData.append("banner", banner);
    formData.append("country", countryId);
    formData.append("state", stateId);
    formData.append("city", cityId);
    formData.append(
      "social_links",
      JSON.stringify([
        { id: "1", Name: "LinkedIn", value: linkedIn },
        { id: "2", Name: "Facebook", value: facebook },
        { id: "3", Name: "Instagram", value: insta },
      ])
    );
    formData.append("is_saas", isSaas);
    formData.append("is_active", active);
    sideImage && formData.append("side_image", sideImage);
    formData.append("primary_color", primaryColor);
    formData.append("secondary_color", secondaryColor);
    formData.append("is_paid", paid);
    paidDate && paid && formData.append("is_paid_date", paidDate);
    formData.append("domain", isSaas && getworkDomain ? getworkDomain : "");
    trial && formData.append("trial_period", trial);
    trialEnd && formData.append("trial_period_end", trialEnd);
    payment && formData.append("amount", payment);
    isDemoGiven && demoDate && formData.append("demo_date", demoDate);
    formData.append("demo_given", isDemoGiven);
    mouSignDate && formData.append("mou_sign_date", mouSignDate);
    invoiceSendDate && formData.append("invoice_send_date", invoiceSendDate);
    totalAffiliatedCollege && formData.append("total_affiliated_college", totalAffiliatedCollege);
    // formData.append('list_of_colleges', JSON.stringify(collegeList));
    var finalCollegeList = [];
    collegeList.map((item) => finalCollegeList.push(item.college_id || item.id));
    formData.append("list_of_colleges", JSON.stringify(finalCollegeList));

    setLoading(true);

    const requestForGroupCreation = {
      method: "PATCH",
      headers: {
        Authorization: `Token ${token}`,
      },
      body: formData,
    };
    apiCall(`/api/admin/education_group/?group_id=${grpId}`, requestForGroupCreation).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setInterval(function () {
          window.location.reload();
        }, 1500);
      } else {
        FailureAlert(res.error);
      }
      setLoading(false);
    });
  };

  // fetch College List
  useEffect(() => {
    apiCall("/api/admin/college/get/", requestPraveshOptions).then((res) => {
      setAllColleges(res.data);
    });
  }, []);

  // Country Finding API
  useEffect(() => {
    apiCall("/api/location/country/", requestPraveshOptions).then((res) => {
      setCountries(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State Finding API
  useEffect(() => {
    apiCall(`/api/location/state/?search=${countryId}`, requestPraveshOptions).then((res) => {
      setStates(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId]);

  // City Finding API
  useEffect(() => {
    apiCall(`/api/location/city/?search=${stateId}`, requestPraveshOptions).then((res) => {
      setCities(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  const deleteCollege = (index) => {
    if (collegeList.length > 0) {
      let arr = [...collegeList];
      arr.splice(index, 1);
      setCollegeList(arr);
    }
  };

  return !loading ? (
    <div className="add__college">
      <Form
        className="add__college__form"
        id="add__college__form__details"
        onSubmit={(e) => {
          editGroup(e);
          // setLoading(true)
        }}
      >
        <h2>Edit Group Details</h2>
        <hr />
        <br />
        <Form.Row>
          <Form.Group controlId="tpoFirstName" as={Col}>
            <Form.Control
              name="tpoFirstName"
              type="text"
              placeholder="Person's First Name*"
              required
              value={tpoFirstName}
              onChange={(e) => {
                setTpoFirstName(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group controlId="tpoLastName" as={Col}>
            <Form.Control
              name="tpoLastName"
              type="text"
              placeholder="Person's Last Name*"
              required
              value={tpoLastName}
              onChange={(e) => {
                setTpoLastName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="tpoDesignation">
          <Form.Label>Person's Designation</Form.Label>
          <Form.Control
            name="designation"
            type="text"
            placeholder="Job Title Goes here.."
            value={tpoDesignation}
            required
            onChange={(e) => {
              setTpoDesignation(e.target.value);
            }}
          />
          {/* <Form.Control
                            as="select"
                            name="tpoDesignation" required
                            value={tpoDesignation}
                            onChange={e => { setTpoDesignation(e.target.value) }}
                        >
                            <option value={tpoDesignation ? tpoDesignation : ""}>
                                {tpoDesignation ? tpoDesignation : "--Select--"}
                            </option>
                            <option value="TPO_ADMIN">TPO ADMIN</option>
                            <option value="TPO_MEMBER">TPO MEMBER</option>
                            <option value="TPO_COORDINATOR">TPO COORDINATOR</option>
                            <option value="PROFESSOR">PROFESSOR</option>
                            <option value="ASST_PROFESSOR">ASST. PROFESSOR</option>
                            <option value="LECTURER">LECTURER</option>
                            <option value="STUDENT">STUDENT</option>
                        </Form.Control> */}
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col} controlId="tpoEmail">
            <Form.Control
              name="tpoEmail"
              type="email"
              placeholder="Email*"
              required
              value={tpoEmail}
              disabled={true}
              onChange={(e) => {
                setTpoEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="tpoContact">
            <Form.Control
              name="tpoContact"
              type="text"
              placeholder="Phone Number*"
              required
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              value={tpoMobile}
              onChange={(e) => {
                setTpoMobile(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="pocEmail">
            <Form.Control
              name="altpocEmail"
              type="email"
              placeholder="TPO Alternate Email (optional)"
              value={altTpoEmail}
              onChange={(e) => {
                setAltTpoEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="pocContact">
            <Form.Control
              name="altpocContact"
              type="text"
              placeholder="TPO Alternate Phone Number (optional)"
              pattern="[0-9]*"
              maxLength="10"
              minLength="10"
              value={altTpoPhone}
              onChange={(e) => {
                setAltTpoPhone(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="institutionAddress">
          <Form.Control
            name="institutionAddress"
            as="textarea"
            placeholder={` Address*`}
            value={address}
            required
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
        </Form.Group>
        <hr />
        <hr />
        <Form.Row>
          <Form.Group controlId="groupName" as={Col}>
            <Form.Label>Group Name*</Form.Label>
            <Form.Control
              name="groupName"
              type="text"
              placeholder="Group Name*"
              required
              value={groupName}
              onChange={(e) => {
                setGroupName(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="year">
            <Form.Label>Year Founded*</Form.Label>
            <Form.Control
              name="year"
              type="number"
              placeholder={`Year Founded*`}
              pattern="[0-9]*"
              maxLength="4"
              minLength="4"
              required
              value={year}
              onChange={(e) => {
                setYear(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>

        <Form.Group controlId="instituteWebsiteUrl">
          <Form.Label>Website URL*</Form.Label>
          <Form.Control
            name="instituteWebsiteUrl"
            type="url"
            placeholder={`Website URL*`}
            value={url}
            required
            onChange={(e) => {
              setUrl(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Label>
          {" "}
          About*
          {aboutCount < 512 ? (
            <i style={{ fontSize: "13px", color: "green" }}> Total Characters: {aboutCount} out of 512</i>
          ) : (
            <i style={{ fontSize: "13px", color: "red" }}> Character Limit Exceeded (max 512 characters) : Character Count : {aboutCount}</i>
          )}
        </Form.Label>
        <Form.Group controlId="instituteAbout">
          <Form.Control
            name="instituteAbout"
            as="textarea"
            rows="2"
            placeholder={` About*`}
            required
            value={about}
            onChange={(e) => {
              setAbout(e.target.value);
              e.target.value && setAboutCount(characterCount(e.target.value));
              !e.target.value && setAboutCount(0);
            }}
          />
        </Form.Group>

        <Form.Row>
          <FormLabel>College List*</FormLabel>
        </Form.Row>
        <Form.Row>
          <Autocomplete
            className="collegeDropdown2"
            id="select-college"
            options={allColleges}
            getOptionLabel={(item) => `${item.college_name} - (${item.college_id}) `}
            onChange={(event, newValue) => {
              if (newValue) {
                setCollegeList((prevState) => [...prevState, newValue]);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Select College" variant="outlined" />}
          />
        </Form.Row>
        {collegeList?.length > 0 && (
          <ul className="collegeList">
            {collegeList.map((item, index) => (
              <li key={item.college_id || item.id} className="collegeListItem">
                {item.college_name || item.name}
                <Button onClick={(e) => deleteCollege(index)} variant="danger" size="sm" style={{ float: "right" }}>
                  Delete
                </Button>
              </li>
            ))}
          </ul>
        )}
        <br />
        <Form.Row>
          <Form.Group as={Col} controlId="affliatedColleges">
            <Form.Label>No. of Affiliated College</Form.Label>
            <Form.Control
              name="affliatedColleges"
              type="number"
              placeholder="0"
              value={totalAffiliatedCollege}
              onChange={(e) => {
                setTotalAffiliatedCollege(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <Form.Label>Social Links : </Form.Label>
        <Form.Group controlId="linkedInPage">
          <Form.Control
            name="linkedInPage"
            type="text"
            placeholder={`LinkedIn Page`}
            value={linkedIn}
            onChange={(e) => {
              setLinkedIn(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="facebookPage">
          <Form.Control
            name="facebookPage"
            type="text"
            placeholder={`Facebook Page`}
            value={facebook}
            onChange={(e) => {
              setFacebook(e.target.value);
            }}
          />
        </Form.Group>
        <Form.Group controlId="instaAccount">
          <Form.Control
            name="instaAccount"
            type="text"
            placeholder={`Instagram Account`}
            value={insta}
            onChange={(e) => {
              setInsta(e.target.value);
            }}
          />
        </Form.Group>

        <h4>Location*</h4>
        <Form.Row className="typeSearchLocation">
          <Form.Group as={Col} controlId="country">
            <Autocomplete
              id="select-country"
              options={countries}
              getOptionLabel={(item) => item.country}
              onChange={(e, item) => (item ? setCountryId(item.country_id) : setCountryId(1))}
              renderInput={(params) => <TextField {...params} label={`Select Country - ${countryName}`} variant="outlined" />}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="state">
            <Autocomplete
              id="select-state"
              options={states}
              getOptionLabel={(item) => item.state}
              onChange={(e, item) => (item ? setStateId(item.state_id) : setStateId(0))}
              renderInput={(params) => <TextField {...params} label={`Select State - ${stateName}`} variant="outlined" />}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="city">
            <Autocomplete
              id="select-city"
              options={cities}
              getOptionLabel={(item) => item.city}
              onChange={(e, item) => (item ? setCityId(item.city_id) : setCityId(0))}
              renderInput={(params) => <TextField {...params} label={`Select City - ${cityName}`} variant="outlined" />}
            />
          </Form.Group>
        </Form.Row>
        <hr />
        <hr />

        <Form.Label>
          <u>SAAS*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="isSaas" required>
          <Form.Check inline id="yes" type="radio" name="SAAS" label="Yes" checked={isSaas} onChange={(e) => setIsSaas(!isSaas)} />
          <Form.Check inline id="no" type="radio" name="SAAS" label="No" checked={!isSaas} onChange={(e) => setIsSaas(!isSaas)} />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <u>Paid*</u>{" "}
            </Form.Label>
          </Form.Group>
          {paid && (
            <Form.Group as={Col}>
              <Form.Label name="paidDate">
                <u>Paid Date</u>
              </Form.Label>
            </Form.Group>
          )}
        </Form.Row>
        <Form.Row>
          <Form.Group type="radio" id="paid" as={Col} required>
            <Form.Check inline id="yes" type="radio" name="paid" label="Yes" checked={paid} onChange={(e) => setPaid(!paid)} />
            <Form.Check inline id="no" type="radio" name="paid" label="No" checked={!paid} onChange={(e) => setPaid(!paid)} />
          </Form.Group>
          {paid && (
            <Form.Group as={Col} controlId="paidDate">
              <Form.Control
                name="paidDate"
                type="date"
                placeholder="YYYY-MM-DD"
                value={paidDate}
                onChange={(e) => {
                  setPaidDate(e.target.value);
                }}
              />
            </Form.Group>
          )}
        </Form.Row>
        <Form.Label>
          <u>Active*</u>{" "}
        </Form.Label>
        <Form.Group type="radio" id="active" required>
          <Form.Check inline id="yes" type="radio" name="active" label="Yes" checked={active} onChange={(e) => setActive(!active)} />
          <Form.Check inline id="no" type="radio" name="active" label="No" checked={!active} onChange={(e) => setActive(!active)} />
        </Form.Group>
        {isSaas && (
          <Form.Group controlId="GetWorkDomain" required>
            <Form.Label>GetWork Domain(For SAAS Only):</Form.Label>
            <Form.Control
              name="GetWork Domain"
              type="text"
              placeholder="GetWork Domain"
              value={getworkDomain}
              onChange={(e) => {
                setGetworkDomain(e.target.value);
              }}
            />
          </Form.Group>
        )}
        <br />
        <Form.Row>
          <Form.Group as={Col} controlId="trial">
            <Form.Label>Trial Period Start Date</Form.Label>
            <Form.Control
              name="trial"
              type="date"
              placeholder="YYYY-MM-DD"
              value={trial}
              onChange={(e) => {
                setTrial(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="trialEndDate">
            <Form.Label>Trial Period End Date</Form.Label>
            <Form.Control
              name="trialEndDate"
              type="date"
              placeholder="YYYY-MM-DD"
              min={trial ? trial : ""}
              value={trialEnd}
              onChange={(e) => {
                setTrialEnd(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group as={Col} controlId="payment">
            <Form.Label>Payment Pending (optional)</Form.Label>
            <NumberFormat
              thousandSeparator={true}
              thousandsGroupStyle="lakh"
              prefix={"₹"}
              className="form-control"
              name="payment"
              type="text"
              placeholder="Enter Amount"
              value={payment}
              onValueChange={(values) => {
                const { value } = values;
                setPayment(value);
              }}
            />
          </Form.Group>
        </Form.Row>
        <br />

        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label>
              <u>Demo Given*</u>{" "}
            </Form.Label>
          </Form.Group>
          {isDemoGiven ? (
            <Form.Group as={Col}>
              <Form.Label>
                <u>Demo Date*</u>
              </Form.Label>
            </Form.Group>
          ) : null}
        </Form.Row>
        <Form.Row>
          <Form.Group type="radio" id="demoGiven" as={Col} required>
            <Form.Check inline id="yes" type="radio" name="demoGiven" label="Yes" checked={isDemoGiven} onChange={(e) => setDemoGiven(!isDemoGiven)} />
            <Form.Check inline id="no" type="radio" name="demoGiven" label="No" checked={!isDemoGiven} onChange={(e) => setDemoGiven(!isDemoGiven)} />
          </Form.Group>
          {isDemoGiven ? (
            <Form.Group as={Col} controlId="demoDate">
              <Form.Control
                name="demoDate"
                type="date"
                placeholder="YYYY-MM-DD"
                required={isDemoGiven ? true : false}
                value={demoDate}
                onChange={(e) => {
                  setDemoDate(e.target.value);
                }}
              />
            </Form.Group>
          ) : null}
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="mouSignDate">
            <Form.Label>Mou Sign Date</Form.Label>
            <Form.Control
              name="mouSignDate"
              type="date"
              placeholder="YYYY-MM-DD"
              value={mouSignDate}
              onChange={(e) => {
                setMouSignDate(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="invoiceDate">
            <Form.Label>Invoice Send Date</Form.Label>
            <Form.Control
              name="invoiceDate"
              type="date"
              placeholder="YYYY-MM-DD"
              value={invoiceSendDate}
              onChange={(e) => {
                setInvoiceSendDate(e.target.value);
              }}
            />
          </Form.Group>
        </Form.Row>
        {isSaas && (
          <>
            <Form.Group controlId="primaryColor" required>
              <Form.Label>Select Primary Color(For SAAS Only):</Form.Label>
              <ColorPicker color={primaryColor} setColor={setPrimaryColor} />
            </Form.Group>
            <br />

            <Form.Group controlId="secondaryColor" required>
              <Form.Label>Select Secondary Color(For SAAS Only):</Form.Label>
              <ColorPicker color={secondaryColor} setColor={setSecondaryColor} />
            </Form.Group>
          </>
        )}
        <br />
        {fetchedLogo && !logo && <img src={fetchedLogo} alt="Logo" width="200px" height="200px" />}
        <Form.File id="collegeLogo">
          <Form.File.Label>Upload {isSaas && "Rectangular"} Logo*</Form.File.Label>
          <Form.File.Input onChange={(e) => setLogo(e.target.files[0])} />
          <p>Recommended Size: {isSaas ? "150px*50px" : "150px*150px"}</p>
        </Form.File>
        <br />
        {fetchedCircularLogo && !circularLogo && <img src={fetchedCircularLogo} alt="Logo" width="100px" height="100px" />}
        <Form.File id="collegeLogo">
          <Form.File.Label>Upload Circular Logo*</Form.File.Label>
          <Form.File.Input onChange={(e) => setCircularLogo(e.target.files[0])} />
          <p>Recommended Size: {isSaas ? "150px*150px" : "150px*150px"}</p>
        </Form.File>
        <br />
        {fetchedBanner && !banner && <img src={fetchedBanner} alt="Logo" width="200px" height="200px" />}
        <Form.File id="collegeBanner">
          <Form.File.Label>Upload Banner*</Form.File.Label>
          <Form.File.Input onChange={(e) => setBanner(e.target.files[0])} />
          <p>Recommended Size: 860px*250px</p>
        </Form.File>
        <br />
        {fetchedFavicon && !favicon && <img src={fetchedFavicon} alt="Logo" width="60px" height="60px" />}
        <Form.File id="collegeLogo">
          <Form.File.Label>Upload Favicon*</Form.File.Label>
          <Form.File.Input
            onChange={(e) => {
              if (e.target.files[0]?.name?.includes("ico")) setFavicon(e.target.files[0]);
              else FailureAlert("Please Enter .ico extension file");
            }}
          />
          <p>Recommended Size: {isSaas ? "16px*16px" : "16px*16px"}</p>
        </Form.File>
        <br />
        {isSaas && (
          <>
            {fetchedSideImage && !sideImage && <img src={fetchedSideImage} alt="Logo" width="200px" height="200px" />}
            <Form.File id="collegeSideImage">
              <Form.File.Label>Upload Side Image(For SAAS Only)</Form.File.Label>
              <Form.File.Input onChange={(e) => setSideImage(e.target.files[0])} />
              <p>Recommended Size: 720px*700px</p>
            </Form.File>
          </>
        )}
        <br />
        <br />
        <Button type="submit" variant="info">
          Edit Group
        </Button>
      </Form>
    </div>
  ) : (
    <img alt="Getwork Loading Gif..." src={gif} style={gifStyling} />
  );
}

export default EditGroupSection;
