import { AppBar, CircularProgress, Tab, Tabs } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import { useTheme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Autocomplete } from "@material-ui/lab";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import PropTypes from "prop-types";
import QueryString from "query-string";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Table } from "react-bootstrap";
import Button2 from "@material-ui/core/Button";
import { WhatsappIcon, WhatsappShareButton } from "react-share";
import UseAxiosApi from "../../Api/UseAxiosApi";
import gif from "../../assets/loopingcircles-3.gif";
import {
  cellCSS,
  createIconStyling,
  gifStyling,
  headerCSS,
  praveshUrl,
  requestNiyuktiOptions,
  requestPraveshOptions,
} from "../../constants";
import { apiCall, apiNiyuktiCall } from "../../util/apiCall";
import { copyToClipboard } from "../../util/copyToClipboard";
import { FailureAlert, SuccessAlert } from "../../util/Notifications";
import { printNumber, validateDateTime } from "../../util/printNumber";
import { TabPanel } from "../../util/TabPanel";
import { uniqueArray } from "../../util/uniqueArray";
import {
  createLocationString,
  createPublicJobLink,
  createGetWorkPublicLink,
} from "../../util/utility";
import { viewJobDetail } from "../../util/ViewJobFunction";
import OpenCloseJobFilter from "../Jobs/Dialog/OpenCloseJobFilter";
import CustomButtonCircularProgress from "../loader";
import TableWithExport from "../TableWithExport";
import ShareDialog from "../Vendor/SharePopUp/ShareDialog";
import ViewOfferLetter from "./Dialog/ViewOfferLetter";
import ShareOutlinedIcon from "@material-ui/icons/ShareOutlined";
import AddAdminUser from "./Dialog/AddAdminUser";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router";

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `wrapped-tab-${index}`,
    "aria-controls": `wrapped-tabpanel-${index}`,
  };
}

const labelStyle = {
  fontSize: "13px",
  marginBottom: "1px",
  color: "green",
};

function OpenCloseJobs({ job_status, history, offCampus }) {
  const urlHistory = useHistory();

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [circularLoading, setCircularLoading] = useState(false);
  const [onCampusData, setOnCampusData] = useState([]);
  useEffect(() => console.log("onCampusData", onCampusData), [onCampusData]);
  const [offCampusData, setOffCampusData] = useState([]);
  const [hasOnCampusData, setHasOnCampusData] = useState(false);
  const [hasOffCampusData, setHasOffCampusData] = useState(false);
  const theme = useTheme();
  const [value, setValue] = useState(1);

  const [preSelectedAdminUser, setPreSelectedAdminUser] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalCollegesData, setModalCollegesData] = useState([]);
  const [modalAssignmentsData, setModalAssignmentsData] = useState([]);
  const [modalCloseJobData, setModalCloseJobData] = useState({});
  const [closeReason, setCloseReason] = useState("");
  const [count, setCount] = useState(0);
  const [jobId, setJobId] = useState(null);
  const [jobTitle, setJobTitle] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const [uploadAssignment, setUploadAssignment] = useState(false);
  const [viewCollegeModal, setViewCollegeModal] = useState(false);
  const [viewAssignmentModal, setViewAssignmentModal] = useState(false);
  const [uploadAssignmentModal, setUploadAssignmentModal] = useState(false);
  const [viewExtraDataModal, setViewExtraDataModal] = useState(false);
  const [extraData, setExtraData] = useState({});
  const [jobStage, setJobStage] = useState("");
  const [followUp1, setFollowUp1] = useState("");
  const [followUp2, setFollowUp2] = useState("");
  const [followUp3, setFollowUp3] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [deadline, setDeadline] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState(null);
  const [statusArr, setStatusArr] = useState([]);
  const [currentJobAndCompanyId, setCurrentJobAndCompanyId] = useState(null);

  const queryParams = QueryString.parse(window.location.search);
  let date = new Date();
  date.setDate(date.getDate() - 5);
  const dateRange = [date, new Date()];

  const [filters, setFilters] = useState({
    advanceFilterDateValue: dateRange,
    selectedCompanyList: [],
    selectedPlanList: [],
    selectedJobCategory: [
      {
        id: "PREMIUM",
        name: "Premium",
      },
    ],
  });

  const [showFilter, setShowFilter] = useState(false);
  const [showAddAdminUser, setShowAddAdminUser] = useState(false);
  const [selectedRowJobId, setSelectedRowJobId] = useState(null);

  //for downloading all data
  const dateString = useRef("");
  const [canDownload, setCanDownload] = useState(false);

  const [openOfferLetter, setOpenOfferLetter] = useState(false);

  const [shareJobProfile, setShareJobProfile] = useState(false);

  const [currentSelectedRow, setCurrentSelectedRow] = useState(false);

  const [selectedRowIndex, setSelecteRowIndex] = useState();

  const clickOnShare = (row) => {
    setShareJobProfile(true);
    setCurrentSelectedRow(row);
  };

  const statusObj = [
    { name: "Applied Candidates", id: 1 },
    { name: "Reviewed Candidates", id: 8 },
    { name: "Shortlisted Candidates", id: 2 },
    { name: "Interviewed Candidates", id: 7 },
  ];

  const handleChangeDate = (newRange, props) => {
    let startDate = (newRange && newRange[0]) || new Date(2021, 0, 1);
    let endDate = (newRange && newRange[1]) || new Date();
    props.onFilterChanged(props.columnDef.tableData.id, [startDate, endDate]);
  };

  useEffect(() => {
    if (startDate && endDate) {
      let month = startDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = startDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${startDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      );
      dateString.current = `&start_date=${startDateString}&end_date=${endDate
        .toISOString()
        .substring(0, 10)}`;
    }
  }, [startDate, endDate]);

  // dowload all data and get email for all colleges

  const downloadCallbackFn = (data) => {
    SuccessAlert(data?.message ?? "Link to download data will arrive in mail");
  };

  let downloadDateString = "";
  if (queryParams.from && queryParams.to)
    downloadDateString = `&start_date=${queryParams.from}&end_date=${queryParams.to}`;

  const endPoint = `${praveshUrl}/api/admin/job/detail/?job_status=${job_status}&hiring_type=${
    value === 0 ? "ON CAMPUS" : "OFF CAMPUS"
  }${downloadDateString ? downloadDateString : ""}&is_export=True`;
  const { apiCall: downloadAllDataCSV, loading: loadingAllDataDownload } =
    UseAxiosApi({
      url: endPoint,
      mountingCallFlag: canDownload,
      callBackFn: downloadCallbackFn,
    });

  useEffect(() => {
    if (canDownload) {
      downloadAllDataCSV();
    }
  }, [canDownload]);

  // useEffect(()=>{
  //  apiCall(`/api/admin/job/company-list/`,requestPraveshOptions)
  //  .then((json)=>{
  //   if(json?.success){
  //     setCompanyList(json?.data)
  //   }else{
  //     FailureAlert(json?.error)
  //   }

  //  })
  //  .catch((error)=>{
  //   FailureAlert('An error occure')
  //  })

  //  apiCall(`/api/admin/job/plan-list/`, requestPraveshOptions)
  //  .then((json)=>{
  //   if(json?.success){
  //     setPlanList(json?.data)
  //   }
  //   else{
  //     FailureAlert(json?.error)
  //   }
  //  })
  //  .catch((error)=>{
  //   FailureAlert('An error occure')
  //  })
  // },[])

  const handleClose = () => {
    setOpen(false);
    // setUploadAssignment(false);
    setViewCollegeModal(false);
    setViewAssignmentModal(false);
    setUploadAssignmentModal(false);
    setViewExtraDataModal(false);
    setJobId(null);
    setJobTitle("");
    setCompanyName("");
    setModalCollegesData([]);
    setModalCloseJobData({});
    setModalAssignmentsData([]);
    setExtraData({});
    setJobStage("");
    setFollowUp1("");
    setFollowUp2("");
    setFollowUp3("");
  };

  const updateList = () => {
    if (value === 0) setHasOnCampusData(false);
    if (value === 1) setHasOffCampusData(false);
    setCount(count + 1);
  };
  const handleOpen = () => setOpen(true);

  const expLabel = [
    { value: 0, label: "0" },
    { value: 10, label: "10 lpa" },
    { value: 25, label: "25 lpa" },
  ];
  const experience_range_label = [
    { value: 0, label: "0" },
    { value: 7, label: "7 Years" },
    { value: 15, label: "15 Years" },
  ];

  const explabelVacancy = [
    { value: 0, label: "0" },
    { value: 25, label: "25" },
    { value: 50, label: "50" },
  ];

  const handleSlider = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };
  const handleExperienceRangeSlider = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const handleVacancySlider = (newRange, props) => {
    props.onFilterChanged(props.columnDef.tableData.id, newRange);
  };

  const loadCollegesModal = (e, job_id) => {
    setViewCollegeModal(true);
    setCircularLoading(true);
    apiNiyuktiCall(
      `/job/job_college/?job_id=${job_id}`,
      requestNiyuktiOptions
    ).then((res) => {
      if (res?.success) {
        setModalCollegesData(res.data);
        setCircularLoading(false);
      } else FailureAlert(res.error);
    });
    handleOpen();
  };

  const loadAssignmentsModal = (e, job_id) => {
    setJobId(job_id);
    setViewAssignmentModal(true);
    setCircularLoading(true);
    apiNiyuktiCall(
      `/job/job_assignment/?job_id=${job_id}`,
      requestNiyuktiOptions
    ).then((res) => {
      if (res.success) {
        setModalAssignmentsData(res.data);
        setCircularLoading(false);
      } else FailureAlert(res.error);
    });
    handleOpen();
  };

  const uploadAssignmentsModal = (e, job_id, company_name, job_title) => {
    setJobId(job_id);
    setJobTitle(job_title);
    setCompanyName(company_name);
    setUploadAssignmentModal(true);
    setCircularLoading(true);
    handleOpen();
  };

  const loadCloseJobModal = (e, obj) => {
    setModalCloseJobData(obj);
    handleOpen();
  };

  const closeParticularJob = (jobId) => {
    if (!closeReason) {
      FailureAlert("Specify Reason to Close Job!!");
      return;
    }

    const requestOptionsPost = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        job_id: jobId,
        status: "CLOSE",
        job_close_reason: closeReason,
      }),
    };

    apiNiyuktiCall("/job/company/job_status/", requestOptionsPost).then(
      (res) => {
        if (res.success) {
          SuccessAlert(res.data.message);
          setCloseReason("");
        } else {
          FailureAlert(res.error);
        }
        updateList();
        handleClose();
      }
    );
  };

  const addAssignment = (e, jobId) => {
    e.preventDefault();
    const assignmentButton = window.document.getElementById(
      "upload-assignment-btn"
    );
    assignmentButton.disabled = true;
    // if (!file) { FailureAlert('Upload file!!'); return; }
    if (statusArr.length === 0) {
      FailureAlert("Select Candidates Status to assign this assignment!!");
      return;
    }
    const formData = new FormData();
    if (file) {
      const ext = file.name.substring(file.name.lastIndexOf(".") + 1);
      const user_id = JSON.parse(localStorage.getItem("user-data")).id;
      const newName = `${jobTitle}_${user_id}_${jobId}_${Math.floor(
        100000 + Math.random() * 900000
      )}.${ext}`;
      // console.log(newName)
      formData.append("file", file, newName);
    }
    formData.append("job_id", jobId);
    formData.append(
      "user_id",
      JSON.parse(localStorage.getItem("user-data")).id
    );
    formData.append("title", title);
    formData.append("description", description);
    formData.append("end_date", deadline);
    formData.append("assignment_status", "OPEN");
    // formData.append('is_posted_by_admin', true);
    formData.append("url", url);
    formData.append("job_title", jobTitle);
    formData.append("company_name", companyName);
    let statusTemp = uniqueArray(statusArr);
    formData.append(
      "status_id",
      statusTemp.map((e) => e.id)
    );
    const requestForAssignmentUploading = {
      method: "POST",
      headers: { Authorization: `Token ${localStorage.getItem("token")}` },
      body: formData,
    };
    apiNiyuktiCall(
      "/job/company/assignment/",
      requestForAssignmentUploading
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        setTitle("");
        setDescription("");
        setDeadline("");
        setUrl("");
        setFile(null);
        updateList();
        handleClose();
      } else {
        FailureAlert(res.error);
      }
      assignmentButton.disabled = false;
    });
    e.target.reset();
  };

  function getCtcRange(row) {
    return row.ctc_min && row.ctc_max
      ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}`
      : row.ctc_min
      ? `${printNumber(row.ctc_min)}+}`
      : row.ctc_max
      ? `< ${printNumber(row.ctc_max)}}`
      : null;
  }

  function getSalaryText(detail, count) {
    let return_text = "";
    if (detail.job_type_id === 1) {
      return_text += `*${count++}. Stipend:* Rs. ${printNumber(
        detail.stipend
      )} per month \n*${count++}. PPO:* ${detail.ppo ? "Yes" : "No"} \n`;
      if (detail.ppo) {
        return_text += `*${count++}. CTC After PPO:* Rs. ${getCtcRange(
          detail
        )} \n`;
      }
    } else {
      return_text += `*${count++}. CTC:* ${getCtcRange(detail)} \n`;
    }
    return_text += `*${count++}. Work from Home:* ${
      detail.work_from_home ? "Yes" : "No"
    } \n*${count++}. Location:* ${createLocationString(detail)} \n*${count++}.`;
    return return_text;
  }

  function contactDetail(detail) {
    var return_text = "";
    if (detail?.company_user) {
      for (var i = 0; i < detail.company_user.length; i++) {
        return_text += `*Contact Details :* \n`;
        return_text += `*1. Name:* ${detail?.company_user[i]?.name}\n*2. Contact:* ${detail?.company_user[i]?.mobile}\n*3. Email:* ${detail?.company_user[i]?.email}\n`;
      }
    }
    return return_text;
  }

  function sharingText(detail) {
    let count = 1;
    return `*${count++}. Company Name:* ${
      detail.company_name
    } \n*${count++}. Job Title:* ${
      detail.job_title
    } \n*${count++}. Job Type:* ${detail.job_type_name} \n${getSalaryText(
      detail,
      count
    )} \n\n${contactDetail(
      detail
    )} \n*Note:* _This job is powered by GetWork! When calling the contact person, always tell that you found this job on GetWork._\n`;
  }

  const loadExtraDataModal = (e, job_id, extra_data) => {
    setJobId(job_id);
    setViewExtraDataModal(true);
    setExtraData(extra_data);
    extra_data?.job_stage && setJobStage(extra_data.job_stage);
    extra_data?.follow_up1 && setFollowUp1(extra_data.follow_up1);
    extra_data?.follow_up2 && setFollowUp2(extra_data.follow_up2);
    extra_data?.follow_up3 && setFollowUp3(extra_data.follow_up3);
    handleOpen();
  };

  const editExtraData = (e, jobId) => {
    e.preventDefault();
    if (!jobId) {
      FailureAlert("Job ID not Selected!!");
      return;
    }
    let temp = { ...extraData };
    temp["job_stage"] = jobStage;
    temp["follow_up1"] = followUp1;
    temp["follow_up2"] = followUp2;
    temp["follow_up3"] = followUp3;

    const requestForExtraDataEdit = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({ extra_data: temp }),
    };
    apiNiyuktiCall(
      `/job/admin/job_stage/${jobId}/`,
      requestForExtraDataEdit
    ).then((res) => {
      if (res.success) {
        SuccessAlert(res.data.message);
        updateList();
        handleClose();
      } else FailureAlert(res.error);
    });
  };

  useEffect(() => {
    let hiring_type = " ";
    if (value === 1) hiring_type = "OFF CAMPUS";
    if (value === 0) hiring_type = "ON CAMPUS";
    if (value === 0 && hasOnCampusData) return;
    if (value === 1 && hasOffCampusData) return;

    let initialStartDate = new Date(
      new Date().setDate(new Date().getDate() - 30)
    );
    let initialEndDate = new Date();

    if (startDate === "") {
      if (queryParams.from) {
        initialStartDate = new Date(queryParams.from);
      }
      if (queryParams.to) {
        initialEndDate = new Date(queryParams.to);
      }
      setStartDate(initialStartDate);
      setEndDate(initialEndDate);
      return;
    } else if (startDate === null) {
      initialStartDate = null;
    } else {
      initialStartDate = new Date(startDate);
      initialEndDate = new Date(endDate);
    }

    let dateString = "";
    if (initialStartDate) {
      let month = initialStartDate.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      let day = initialStartDate.getDate();
      day = day < 10 ? `0${day}` : day;
      let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
      history.replace(
        `?from=${startDateString}&to=${initialEndDate
          .toISOString()
          .substring(0, 10)}`
      );
      // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
      dateString = `&start_date=${startDateString}&end_date=${initialEndDate
        .toISOString()
        .substring(0, 10)}`;
    } else {
      history.replace({
        search: "",
      });
    }

    function convertToPlain(rawHtml) {
      let tempDivElement = document.createElement("div");
      tempDivElement.innerHTML = rawHtml;
      return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    setLoading(true);
    let companyIdsList = [];
    for (var j = 0; j < filters?.selectedCompanyList?.length; j++) {
      companyIdsList?.push(filters?.selectedCompanyList[j]?.id);
    }
    let planIdsList = [];
    for (var j = 0; j < filters?.selectedPlanList?.length; j++) {
      planIdsList?.push(filters?.selectedPlanList[j]?.id);
    }
    let jobCategoryList = [];
    for (var j = 0; j < filters?.selectedJobCategory?.length; j++) {
      jobCategoryList?.push(filters?.selectedJobCategory[j]?.id);
    }

    const companyIds =
      companyIdsList.length > 0
        ? `&company_id=${companyIdsList.join(",")}`
        : "";
    const planIds =
      planIdsList?.length > 0 ? `&plan_id=${planIdsList.join(",")}` : "";
    const categoryIds =
      jobCategoryList?.length > 0
        ? `&job_category=${jobCategoryList.join(",")}`
        : "";
    apiCall(
      `/api/admin/job/detail/?job_status=${job_status}&hiring_type=${hiring_type}${dateString}${companyIds}${planIds}${categoryIds}`,
      requestPraveshOptions
    ).then((json) => {
      if (json?.success) {
        let temp = [];
        for (var i = 0; i < json.data.length; i++) {
          const getWorkLocationMode = () => {
            if (
              json?.data[i]?.work_from_home &&
              json?.data[i]?.location?.length > 0
            ) {
              return "Hybrid";
            } else if (json?.data[i]?.work_from_home) {
              return "Work From Home";
            } else {
              return "Work From Offc";
            }
          };

          let obj = {
            job_users: json.data[i].job_users,
            job_id: json.data[i].job_id,
            job_title: json.data[i].job_title,
            row_index: i,
            job_type_name: json.data[i].job_type_name,
            employment_type: json.data[i].employment_type_name,
            job_type_id: json.data[i].job_type_id,
            ppo: json.data[i].ppo,
            company_user: json.data[i].company_user,
            work_from_home: json.data[i].work_from_home,
            location: json.data[i].location,
            formatted_location: [
              json?.data[i]?.location
                ?.map((item) => `${item?.city || " "}`)
                .toString(),
            ],
            stipend: json.data[i].stipend,
            job_role: json.data[i].job_role,
            job_payment_type: json.data[i].job_payment_type,
            job_tmc: json.data[i].job_tmc
              ? convertToPlain(json.data[i].job_tmc)
              : null,
            hiring_type: json.data[i].hiring_type,
            auto_ai_assessment: json.data[i].auto_ai_assessment,
            colleges: json.data[i].college,
            ctc_min: json.data[i].ctc_min,
            ctc_max: json.data[i].ctc_max,
            ctc_range: `${json.data[i]?.ctc_min / 100000}LPA-${
              json.data[i]?.ctc_max / 100000
            }LPA`,
            exp_min: json.data[i].exp_min,
            exp_max: json.data[i].exp_max,
            experience: json.data[i].experience,
            exp_range: json.data[i].exp_txt,
            vacancy: json.data[i].vacancy || "",
            create_time: validateDateTime(json.data[i].create_time),
            update_time: validateDateTime(json.data[i].update_time),
            equity_min: json.data[i].equity_min,
            equity_max: json.data[i].equity_max,
            interview_count: json.data[i].interview_count,
            applicants: json.data[i].applicants,
            counts: json.data[i].counts || [],
            ai_bot_calling: json.data[i].ai_bot_calling,
            apply_start_date: json.data[i].apply_start_date,
            apply_end_date: json.data[i].apply_end_date,
            company_id: json.data[i].company
              ? json.data[i].company.company_id
              : null,
            company_name: json.data[i].company
              ? json.data[i].company.company_name
              : null,
            company_domain: json.data[i].company
              ? json.data[i].company.company_domain
              : null,
            job_close_reason: json.data[i].job_close_reason,
            job_assignment: json.data[i].job_assignment,
            job_category: json?.data[i]?.job_category,
            serviceBond: json?.data[i]?.service_bond
              ? `Yes  (${json?.data[i]?.service_bond} Months)`
              : "No",
            workLocationMode: getWorkLocationMode(),
            planName: json?.data[i]?.plan_data
              ? ` ${json?.data[i]?.plan_data?.model_name} ${json?.data[i]?.plan_data?.name}`
              : "Free",
            extra_data: json.data[i].extra_data,
            job_lake: json.data[i].job_lake ? "Yes" : "No",
            job_stage:
              json.data[i].extra_data && json.data[i].extra_data.job_stage
                ? json.data[i].extra_data.job_stage
                : "",
            follow_up1:
              json.data[i].extra_data && json.data[i].extra_data.follow_up1
                ? json.data[i].extra_data.follow_up1
                : "",
            follow_up2:
              json.data[i].extra_data && json.data[i].extra_data.follow_up2
                ? json.data[i].extra_data.follow_up2
                : "",
            follow_up3:
              json.data[i].extra_data && json.data[i].extra_data.follow_up3
                ? json.data[i].extra_data.follow_up3
                : "",
            acc_manager_name:
              json.data[i].company && json.data[i].company.account_manager
                ? (json.data[i].company.account_manager.first_name || "") +
                  " " +
                  (json.data[i].company.account_manager.last_name || "")
                : "",
            acc_manager_email:
              json.data[i].company && json.data[i].company.account_manager
                ? json.data[i].company.account_manager.email
                : "",
          };
          let applied = obj.counts.find((o) => o.status === 1);
          obj["applied"] = applied?.count || 0;
          // let reviewed = obj.counts.find(o => o.status === 8)
          // obj['reviewed'] = reviewed?.count || 0
          // let shortlisted = obj.counts.find(o => o.status === 2)
          // obj['shortlisted'] = shortlisted?.count || 0
          let interviewed = obj.counts.find((o) => o.status === 7);
          obj["interviewed"] = interviewed?.count || 0;
          let hired = obj.counts.find((o) => o.status === 13);
          let rejected = obj.counts.find((o) => o.status === 14);
          obj["hired-rejected"] = (rejected?.count || 0) + (hired?.count || 0);
          obj["hired"] = hired?.count || 0;
          obj["rejected"] = rejected?.count || 0;
          let offered = obj.counts.find((o) => o.status === 17);
          obj["offered"] = offered?.count || 0;
          let joined = obj.counts.find((o) => o.status === 18);
          obj["joined"] = joined?.count || 0;
          let waitlisted = obj.counts.find((o) => o.status === 19);
          obj["waitlisted"] = waitlisted?.count || 0;
          let joinedBackout = obj.counts.find((o) => o.status === 22);
          obj["joinedBackout"] = joinedBackout?.count || 0;
          let selectedBackout = obj.counts.find((o) => o.status === 23);
          obj["selectedBackout"] = selectedBackout?.count || 0;
          let interviewedBackout = obj.counts.find((o) => o.status === 24);
          obj["interviewedBackout"] = interviewedBackout?.count || 0;
          let offeredBackout = obj.counts.find((o) => o.status === 25);
          obj["offeredBackout"] = offeredBackout?.count || 0;
          temp.push(obj);
        }
        if (hiring_type === "ON CAMPUS") {
          setOnCampusData(temp);
          setHasOnCampusData(true);
        }
        if (hiring_type === "OFF CAMPUS") {
          setOffCampusData(temp);
          setHasOffCampusData(true);
        }
        setLoading(false);
      } else {
        FailureAlert(json.error);
      }
    });

    // let dateString = "";
    // if (initialStartDate) {
    //   let month = initialStartDate.getMonth() + 1;
    //   month = month < 10 ? `0${month}` : month;
    //   let day = initialStartDate.getDate();
    //   day = day < 10 ? `0${day}` : day;
    //   let startDateString = `${initialStartDate.getFullYear()}-${month}-${day}`;
    //   history.replace(
    //     `?from=${startDateString}&to=${initialEndDate
    //       .toISOString()
    //       .substring(0, 10)}`
    //   );
    //   // window.location.search = `?from=${startDateString}&to=${endDate.toISOString().substring(0, 10)}`
    //   dateString = `&start_date=${startDateString}&end_date=${initialEndDate
    //     .toISOString()
    //     .substring(0, 10)}`;
    // } else {
    //   history.replace({
    //     search: "",
    //   });
    // }

    // function convertToPlain(rawHtml) {
    //   let tempDivElement = document.createElement("div");
    //   tempDivElement.innerHTML = rawHtml;
    //   return tempDivElement.textContent || tempDivElement.innerText || "";
    // }

    // setLoading(true);

    // apiCall(
    //   `/api/admin/job/detail/?job_status=${job_status}&hiring_type=${hiring_type}${dateString}`,
    //   requestPraveshOptions
    // ).then((json) => {
    //   if (json.success) {
    //     // console.log("json data", json.data);
    //     let temp = [];
    //     for (var i = 0; i < json.data.length; i++) {
    //       const getWorkLocationMode = () => {
    //         if (
    //           json?.data[i]?.work_from_home &&
    //           json?.data[i]?.location?.length > 0
    //         ) {
    //           return "Hybrid";
    //         } else if (json?.data[i]?.work_from_home) {
    //           return "Work From Home";
    //         } else {
    //           return "Work From Offc";
    //         }
    //       };

    //       let obj = {
    //         job_id: json.data[i].job_id,
    //         job_title: json.data[i].job_title,
    //         job_type_name: json.data[i].job_type_name,
    //         employment_type: json.data[i].employment_type_name,
    //         job_type_id: json.data[i].job_type_id,
    //         ppo: json.data[i].ppo,
    //         company_user: json.data[i].company_user,
    //         work_from_home: json.data[i].work_from_home,
    //         location: json.data[i].location,
    //         formatted_location: json?.data[i]?.location
    //           ?.map((item) => `${item?.city || " "}`)
    //           ?.toString(),
    //         stipend: json.data[i].stipend,
    //         job_role: json.data[i].job_role,
    //         job_payment_type: json.data[i].job_payment_type,
    //         // job_tmc: json.data[i].job_tmc ? <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(json.data[i].job_tmc) }}></div> : '',

    //         job_tmc: json.data[i].job_tmc
    //           ? convertToPlain(json.data[i].job_tmc)
    //           : null,

    //         hiring_type: json.data[i].hiring_type,
    //         colleges: json.data[i].college,
    //         ctc_min: json.data[i].ctc_min,
    //         ctc_max: json.data[i].ctc_max,
    //         ctc_range: `${json.data[i]?.ctc_min / 100000}LPA-${
    //           json.data[i]?.ctc_max / 100000
    //         }LPA`,
    //         exp_min: json.data[i].exp_min,
    //         exp_max: json.data[i].exp_max,
    //         exp_range: json.data[i].exp_txt,
    //         vacancy: json.data[i].vacancy || "",
    //         create_time: validateDateTime(json.data[i].create_time),
    //         update_time: validateDateTime(json.data[i].update_time),
    //         equity_min: json.data[i].equity_min,
    //         equity_max: json.data[i].equity_max,
    //         applicants: json.data[i].applicants,
    //         counts: json.data[i].counts || [],
    //         offerLetterCount: json.data[i].offer_count,
    //         apply_start_date: json.data[i].apply_start_date,
    //         apply_end_date: json.data[i].apply_end_date,
    //         company_id: json.data[i].company
    //           ? json.data[i].company.company_id
    //           : null,
    //         company_name: json.data[i].company
    //           ? json.data[i].company.company_name
    //           : null,
    //         job_close_reason: json.data[i].job_close_reason,
    //         job_assignment: json.data[i].job_assignment,
    //         job_category: json?.data[i]?.job_category,
    //         serviceBond: json?.data[i]?.service_bond
    //           ? `Yes  (${json?.data[i]?.service_bond} Months)`
    //           : "No",
    //         workLocationMode: getWorkLocationMode(),
    //         planName: json?.data[i]?.plan_data
    //           ? ` ${json?.data[i]?.plan_data?.model_name} ${json?.data[i]?.plan_data?.name}`
    //           : "Free",
    //         extra_data: json.data[i].extra_data,
    //         job_lake: json.data[i].job_lake ? "Yes" : "No",
    //         job_stage:
    //           json.data[i].extra_data && json.data[i].extra_data.job_stage
    //             ? json.data[i].extra_data.job_stage
    //             : "",
    //         follow_up1:
    //           json.data[i].extra_data && json.data[i].extra_data.follow_up1
    //             ? json.data[i].extra_data.follow_up1
    //             : "",
    //         follow_up2:
    //           json.data[i].extra_data && json.data[i].extra_data.follow_up2
    //             ? json.data[i].extra_data.follow_up2
    //             : "",
    //         follow_up3:
    //           json.data[i].extra_data && json.data[i].extra_data.follow_up3
    //             ? json.data[i].extra_data.follow_up3
    //             : "",
    //         acc_manager_name:
    //           json.data[i].company && json.data[i].company.account_manager
    //             ? (json.data[i].company.account_manager.first_name || "") +
    //               " " +
    //               (json.data[i].company.account_manager.last_name || "")
    //             : "",
    //         acc_manager_email:
    //           json.data[i].company && json.data[i].company.account_manager
    //             ? json.data[i].company.account_manager.email
    //             : "",
    //       };
    //       let applied = obj.counts.find((o) => o.status === 1);
    //       obj["applied"] = applied?.count || 0;
    //       // let reviewed = obj.counts.find(o => o.status === 8)
    //       // obj['reviewed'] = reviewed?.count || 0
    //       // let shortlisted = obj.counts.find(o => o.status === 2)
    //       // obj['shortlisted'] = shortlisted?.count || 0
    //       let interviewed = obj.counts.find((o) => o.status === 7);
    //       obj["interviewed"] = interviewed?.count || 0;
    //       let hired = obj.counts.find((o) => o.status === 13);
    //       let rejected = obj.counts.find((o) => o.status === 14);
    //       obj["hired-rejected"] = (rejected?.count || 0) + (hired?.count || 0);
    //       let offered = obj.counts.find((o) => o.status === 17);
    //       obj["offered"] = offered?.count || 0;
    //       let joined = obj.counts.find((o) => o.status === 18);
    //       obj["joined"] = joined?.count || 0;
    //       let waitlisted = obj.counts.find((o) => o.status === 19);
    //       obj["waitlisted"] = waitlisted?.count || 0;
    //       temp.push(obj);
    //     }
    //     if (hiring_type === "ON CAMPUS") {
    //       setOnCampusData(temp);
    //       setHasOnCampusData(true);
    //     }
    //     if (hiring_type === "OFF CAMPUS") {
    //       setOffCampusData(temp);
    //       setHasOffCampusData(true);
    //     }
    //     setLoading(false);
    //   } else {
    //     FailureAlert(json.error);
    //   }
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, startDate, value]);

  const [selectedRecruiterList, setSelectedRecruiterList] = useState([]);

  const [addUserAdminClick, setAddUserAdminClick] = useState(false);
  useEffect(() => console.log("first", addUserAdminClick), [addUserAdminClick]);
  useEffect(() => {
    // const rowData = onCampusData.map(row => {
    //   if (row.job_id === selectedJobId) {
    //     return { ...row, job_users: [] };
    //   }
    //   return job;
    // });
    if (addUserAdminClick) {
      let hiring_type;
      console.log("value", value);
      if (value === 1) hiring_type = "OFF CAMPUS";
      if (value === 0) hiring_type = "ON CAMPUS";

      if (hiring_type === "OFF CAMPUS") {
        var tempData = [...offCampusData];
        tempData[selectedRowIndex]["job_users"] = selectedRecruiterList;
        setOffCampusData(tempData);
        setSelectedRecruiterList([]);
      }
      if (hiring_type === "ON CAMPUS") {
        var tempData = [...onCampusData];
        tempData[selectedRowIndex]["job_users"] = selectedRecruiterList;
        setOnCampusData(tempData);
        setSelectedRecruiterList([]);
      }
      setAddUserAdminClick(false);
    }
  }, [addUserAdminClick]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCanDownload(false);
  };

  const editAndViewJob = (jobId, CompanyId) => {
    window.open(`/admin/edit-job?jobId=${jobId}&companyId=${CompanyId}`);
  };

  const columns = [
    {
      title: "Job_ID",
      headerStyle: {
        position: "sticky",
        left: 0,
        zIndex: 1000,
      },
      cellStyle: {
        position: "sticky",
        left: 0,
        backgroundColor: "#fff",
        fontSize: 13,
      },
      filterCellStyle: {
        position: "sticky",
        left: 0,
        zIndex: 100,
        backgroundColor: "#fff",
      },
      field: "job_id",
      render: (row) =>
        row.job_id ? (
          <div style={{ display: "flex", flexDirection: "col" }}>
            <div style={{ paddingRight: "5px" }}>{row.job_id}</div>
            <WhatsappShareButton
              style={{ outline: "none", display: "flex" }}
              url={createGetWorkPublicLink(row, "openclosejobs")}
              title={sharingText(row)}
              separator="*Job Link:*"
            >
              <WhatsappIcon round={true} size={32} />
            </WhatsappShareButton>
          </div>
        ) : null,
    },
    {
      title: "Company_Name",
      field: "company_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job_Title",
      field: "job_title",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job_Type",
      field: "job_type_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: {
        Internship: "Internship",
        "On campus student employment": "On campus student employment",
        Fellowship: "Fellowship",
        "Part Time Job": "Part Time Job",
        Volunteer: "Volunteer",
        Training: "Training",
        "Full Time Job": "Full Time Job",
      },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Ai_Assessment",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => <div>{row?.auto_ai_assessment ? "Yes" : "No"}</div>,
    },
    {
      title: "Assigned To",
      field: "job_users",
      headerStyle: headerCSS,
      cellStyle: {
        fontSize: "13px",
        padding: "6px 15px",
        height: "50px",
        textAlign: "left",
        minWidth: "200px",
      },
      filtering: false,
      render: (row) => (
        <div>
          {!row.job_users.length ? (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() => {
                setShowAddAdminUser(true);
                setSelectedRowJobId(row.job_id);
                setSelecteRowIndex(row.row_index);
                setPreSelectedAdminUser(row.job_users);
              }}
            >
              {"Add"}
            </Button>
          ) : (
            <>
              {row.job_users.map((user) => user.admin_name).join(", ")}
              <EditIcon
                variant="outline-info"
                style={{ cursor: "pointer", fontSize: "14px" }}
                onClick={() => {
                  setShowAddAdminUser(true);
                  setSelectedRowJobId(row.job_id);
                  setSelecteRowIndex(row.row_index);
                  setPreSelectedAdminUser(row.job_users);
                }}
              />
            </>
          )}
        </div>
      ),
    },
    {
      title: "View_AI_Bot_Details",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <>
          {row?.ai_bot_calling ? (
            <Button
              variant="outline-info"
              size="sm"
              style={{
                fontSize: 13,
              }}
              onClick={() =>
                urlHistory.push(
                  `/admin/ai-calling-bot/${row.job_id}/${row.job_title}`
                )
              }
            >
              View Bot Details
            </Button>
          ) : (
            <div>AI Bot not created</div>
          )}
        </>
      ),
    },
    {
      title: "View_Job Details",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={() => viewJobDetail(row.job_id)}
        >
          View Job
        </Button>
      ),
    },
    {
      title: "Applicants",
      field: "applicants",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <div>
          {row.applicants > 0 ? (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
              }
            >
              {`View (${row.applicants})`}
            </Button>
          ) : (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/job-track/${row.job_id}/${row.company_id}`)
              }
            >
              {`View (${row.applicants})`}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Interview Count",
      field: "interview_count",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // lookup: jobCategorySearchObject,
      searchable: false,
      // filterPlaceholder: "Select",
      render: (row) => (
        <div>
          {row.interview_count > 0 ? (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/all-interviews/?job_id=${row.job_id}`)
              }
            >
              {`View (${row.interview_count})`}
            </Button>
          ) : (
            <Button
              variant="outline-info"
              size="sm"
              onClick={() =>
                window.open(`/admin/all-interviews/?job_id=${row.job_id}`)
              }
            >
              {`View (${row.interview_count})`}
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Job Category",
      field: "job_category",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      // lookup: jobCategorySearchObject,
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Work Mode",
      field: "workLocationMode",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      // filterPlaceholder: "Select",
    },
    {
      title: "Location",
      field: "formatted_location",
      headerStyle: headerCSS,
      // cellStyle: cellCSS,
      cellStyle: {
        minWidth: 180,
        maxWidth: 180,
        fontSize: "13px",
        padding: "6px 25px",
        marginRight: "5px",
        overflow: "hidden",
      },

      customFilterAndSearch: (term, rowData) => {
        let searchTerms = [];
        if (term) {
          searchTerms = term.split(",").map(function (item) {
            return item.trim();
          });
          // if (searchTerms.length > 1)
          //     searchTerms = searchTerms.slice(0, -1);
        }
        const bb = searchTerms.reduce((prev, currVal) => {
          if (currVal)
            rowData?.formatted_location?.forEach((item) => {
              prev =
                prev || item?.toLowerCase().includes(currVal?.toLowerCase());
            });
          return prev;
        }, false);
        return bb;
      },
    },
    {
      title: "CTC_Range",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      field: "ctc_range",
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 25]}
          defaultValue={[0, 25]}
          onChange={(e, val) => handleSlider(val, props)}
          min={0}
          max={25}
          step={2}
          marks={expLabel}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e} lpa`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData.ctc_min / 100000 >= term[0] &&
          rowData.ctc_max / 100000 <= term[1]) ||
        rowData.ctc_max / 100000 >= term[0],

      render: (row) => (
        <div>
          {row.ctc_min && row.ctc_max
            ? `${printNumber(row.ctc_min)}-${printNumber(row.ctc_max)}`
            : row.ctc_min
            ? `${printNumber(row.ctc_min)}+}`
            : row.ctc_max
            ? `< ${printNumber(row.ctc_max)}}`
            : null}
        </div>
      ),
    },
    {
      title: "Stipend",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      field: "stipend",
      render: (row) => (
        <div>{row.stipend ? `${printNumber(row.stipend)}` : null}</div>
      ),
    },

    //experiece

    //     { title: "Job Approval",
    //     field: "job_category",
    //     headerStyle: headerCSS,
    //     cellStyle: cellCSS ,
    //     searchable: true,
    //     filterPlaceholder: "Select",
    //    },

    {
      title: "Experience_Range",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      field: "exp_range",
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 15]}
          defaultValue={[0, 15]}
          onChange={(e, val) => handleExperienceRangeSlider(val, props)}
          min={0}
          max={15}
          step={2}
          marks={experience_range_label}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e}`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        (rowData?.experience?.min >= term[0] &&
          rowData?.experience?.max <= term[1]) ||
        rowData?.experience?.max >= term[0],
      render: (row) => (
        <div>
          {/* {row.exp_min && row.exp_max
            ? `${row.exp_min}-${row.exp_max} Years`
            : row.exp_min
            ? `${row.exp_min}+ Years`
            : row.exp_max
            ? `< ${row.exp_max} Years`
            : "NA"} */}
          {row?.experience ? row?.experience : "NA"}
        </div>
      ),
    },
    {
      title: "Vacancies",
      field: "vacancy",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: false,
      filterComponent: (props) => (
        <Slider
          style={{ zIndex: "10" }}
          value={props.columnDef.tableData.filterValue || [0, 50]}
          defaultValue={[0, 50]}
          onChange={(e, val) => handleVacancySlider(val, props)}
          min={0}
          max={50}
          step={5}
          marks={explabelVacancy}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
          valueLabelFormat={(e) => `${e}`}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        rowData.vacancy >= term[0] && rowData.vacancy <= term[1],
      render: (row) => `${row.vacancy}`,
    },
    {
      title: "Job_Terms_&_Conditions",
      field: "job_tmc",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Company_Domain",
      field: "company_domain",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Plan Name",
      field: "planName",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      filterPlaceholder: "Select",
    },
    {
      title: "Service Bond",
      field: "serviceBond",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      // filterPlaceholder: "Select",
    },
    {
      title: "Account_Manager_Name",
      field: "acc_manager_name",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Account_Manager_Email",
      field: "acc_manager_email",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      render: (row) =>
        row.acc_manager_email ? (
          <div>
            {row.acc_manager_email}
            <FileCopyIcon
              style={createIconStyling}
              onClick={(e) => copyToClipboard(row.acc_manager_email)}
            />
          </div>
        ) : null,
    },
    {
      title: "Employment",
      field: "employment_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { "Full Time": "Full Time", "Part Time": "Part Time" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Job_Role",
      field: "job_role",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Job_Payment_Type",
      field: "job_payment_type",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      lookup: { PAID: "PAID", UNPAID: "UNPAID" },
      searchable: false,
      filterPlaceholder: "Select",
    },
    {
      title: "Creation_Date",
      field: "create_time",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      type: "datetime",
      searchable: false,
      filterComponent: (props) => (
        <DateRangePicker
          rangeDivider="to"
          value={props.columnDef.tableData.filterValue || dateRange}
          onChange={(e) => handleChangeDate(e, props)}
        />
      ),
      customFilterAndSearch: (term, rowData) =>
        Date.parse(term[0]) <= Date.parse(rowData.create_time) &&
        Date.parse(term[1]) >= Date.parse(rowData.create_time),
    },
    {
      title: "Apply_Start_Date",
      field: "apply_start_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Open Calendar",
      type: "date",
    },
    {
      title: "Apply_End_Date",
      field: "apply_end_date",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filterPlaceholder: "Open Calendar",
      type: "date",
    },
    {
      title: "Edit & View",
      field: "edit&View",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      searchable: true,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
            minWidth: 100,
          }}
          onClick={() => editAndViewJob(row?.job_id, row?.company_id)}
        >
          Edit & View
        </Button>
      ),
      filterPlaceholder: "Select",
    },

    ...(value === 0
      ? [
          {
            title: "Colleges",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <Button
                variant="outline-info"
                size="sm"
                style={{
                  fontSize: 13,
                }}
                onClick={(e) => loadCollegesModal(e, row.job_id)}
              >
                Colleges
              </Button>
            ),
          },
        ]
      : []),

    // ...value === 0 ? [{
    //     title: "Posted_On_Job_Lake?", field: 'job_lake', headerStyle: headerCSS, cellStyle: cellCSS
    // }] : [],
    ...(value === 0
      ? [
          {
            title: "Posted_On_Job_Lake?",
            field: "job_lake",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            lookup: { Yes: "Yes", No: "No" },
            searchable: false,
            filterPlaceholder: "Select",
          },
        ]
      : []),
    // {
    //   title: "Job_Stage",
    //   field: "job_stage",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    // },
    // {
    //   title: "Follow_Up_1",
    //   field: "follow_up1",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    // },
    // {
    //   title: "Follow_Up_2",
    //   field: "follow_up2",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    // },
    // {
    //   title: "Follow_Up_3",
    //   field: "follow_up3",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    // },

    {
      title: "Applied",
      field: "applied",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Waitlisted",
      field: "waitlisted",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Interviewed",
      field: "interviewed",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Offered",
      field: "offered",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Joined",
      field: "joined",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Hired",
      field: "hired",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Rejected",
      field: "rejected",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Joined Backout",
      field: "joinedBackout",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Selected Backout",
      field: "selectedBackout",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Interviewed Backout",
      field: "interviewedBackout",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    {
      title: "Offered Backout",
      field: "offeredBackout",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
    },
    // {
    //   title: "Hired/Rejected",
    //   field: "hired-rejected",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    // },
    // {
    //   title: "Offers Count",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   field: "olCount",
    //   render: (row) => (
    //     <div>{row.olCount ? `${printNumber(row.olCount)}` : null}</div>
    //   ),
    // },
    {
      title: "View offers",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-info"
          size="sm"
          style={{
            fontSize: 13,
          }}
          onClick={() => {
            setCurrentJobAndCompanyId({
              jobId: row?.job_id,
              companyId: row?.company_id,
            });
            setOpenOfferLetter(true);
          }}
        >
          <span style={{ whiteSpace: "noWrap" }}>{`View Offers ${
            row?.offerLetterCount ? `(${row?.offerLetterCount})` : "(0)"
          } `}</span>
        </Button>
      ),
    },

    // {
    //   title: "Extra_Data",
    //   headerStyle: headerCSS,
    //   cellStyle: cellCSS,
    //   filtering: false,
    //   render: (row) => (
    //     <Button
    //       variant="outline-info"
    //       size="sm"
    //       style={{
    //         fontSize: 13,
    //       }}
    //       onClick={(e) => loadExtraDataModal(e, row.job_id, row.extra_data)}
    //     >
    //       <span style={{ whiteSpace: "noWrap" }}>Enter Client Response</span>
    //     </Button>
    //   ),
    // },

    {
      title: "Assignments",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-success"
          style={{
            fontSize: 13,
          }}
          size="sm"
          onClick={(e) => loadAssignmentsModal(e, row.job_id)}
        >
          Assignments
        </Button>
      ),
    },
    {
      title: "Upload_Assignment",
      headerStyle: headerCSS,
      cellStyle: cellCSS,
      filtering: false,
      render: (row) => (
        <Button
          variant="outline-success"
          style={{
            fontSize: 13,
          }}
          size="sm"
          onClick={(e) =>
            uploadAssignmentsModal(
              e,
              row.job_id,
              row.company_name,
              row.job_title
            )
          }
        >
          Upload_Assignment
        </Button>
      ),
    },
    ...(job_status === "OPEN"
      ? [
          {
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {
                  <Button
                    variant="outline-danger"
                    size="sm"
                    style={{ whiteSpace: "nowrap", fontSize: 13 }}
                    onClick={(e) => loadCloseJobModal(e, row)}
                  >
                    Close Job!
                  </Button>
                }
              </div>
            ),
          },
        ]
      : []),
    ...(job_status === "CLOSE"
      ? [
          {
            title: "Reason_To_Close",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => (
              <div>
                {row.job_close_reason ? (
                  <div>{row.job_close_reason}</div>
                ) : (
                  <div style={{ paddingLeft: "10%", fontSize: 13 }}>-</div>
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(value === 1
      ? [
          {
            title: "Share",
            headerStyle: headerCSS,
            cellStyle: cellCSS,
            filtering: false,
            render: (row) => {
              return (
                row?.job_category === "PREMIUM" && (
                  <Button2
                    variant="contained"
                    color="primary"
                    endIcon={<ShareOutlinedIcon />}
                    onClick={() => {
                      clickOnShare(row);
                    }}
                  >
                    Share
                  </Button2>
                )
              );
            },
          },
        ]
      : []),
  ];

  const applyDateFilter = (valueDate) => {
    if (valueDate.length === 2) {
      setStartDate(valueDate[0]);
      setEndDate(valueDate[1]);
      updateList();
    }
  };

  const removeDateFilter = () => {
    setStartDate(null);
    setEndDate(null);
    updateList();
  };

  // const handleApplyFilter = ()=>{
  //   if(selectedCompanyList.length!==0) setCompanyId(`&company_id=${selectedCompanyList.join(',')}`)
  //   if(selectedPlanList.length!==0) setPlanId(`&plan_id=${selectedPlanList.join(',')}`)
  //   setShowFilter(false)
  //   setApplyFilter(applyFilter+1)
  // }

  useEffect(() => {
    applyDateFilter(filters?.advanceFilterDateValue);
  }, [filters]);

  return (
    <div className="open__close__jobs">
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="ON CAMPUS Jobs" {...a11yProps(0)} />
          <Tab label="OFF CAMPUS Jobs" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {value === 0 ? (
        !loading ? (
          <TabPanel value={value} index={0} dir={theme.direction}>
            <br />
            {/* <DateSelector dateRange={[startDate, endDate]} applyDateFilter={applyDateFilter} removeDateFilter={removeDateFilter} /> */}

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "5px",
              }}
            >
              <Button
                style={{
                  width: "8rem",
                  height: "2.5rem",
                  marginRight: "5rem",
                }}
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                Filter
              </Button>

              {/* <DateSelector
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
              /> */}

              <Button
                style={{
                  width: "12rem",
                  height: "2.5rem",
                }}
                onClick={() => {
                  setCanDownload(true);
                  downloadAllDataCSV();
                }}
              >
                {loadingAllDataDownload ? (
                  <CustomButtonCircularProgress />
                ) : (
                  "Download All Data"
                )}
              </Button>
            </div>
            {/* {
              showFilter ?
              <div
              style={{position:'absolute',padding:'1rem',left:'50%', transform:'translate(-50%)',background:'#ffffff', borderRadius:'0.5rem', zIndex:'9999',boxShadow:'0.1rem 0.5rem 1rem 0.1rem #000000'}}>
                <h2>Filter</h2>
                <DateSelector
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
              />
                <Autocomplete
                    multiple
                    id="company-name"
                    options={companyList}
                    getOptionLabel={(option) => option.company_name}
                    onChange={(e,item)=>{
                      item && setSelectedCompanyList(item.map((Item)=>(Item?.id)))
                      console.log(item)
                      console.log(selectedCompanyList)
                    }}
                    style={{ width: 500, margin:'1rem 1rem' }}
                    renderInput={(params) => <TextField {...params} label="Company Name" variant="outlined" />}
                  />
              <Autocomplete
                    multiple
                    id="plan-name"
                    options={planList}
                    getOptionLabel={(option) => option?.name}
                    onChange={(e,item)=>{
                      item && setSelectedPlanList(item.map((Item)=>(Item?.id)))
                      console.log(item)
                      console.log(selectedPlanList)
                    }}
                    style={{ width: 500, margin:'1rem 1rem' }}
                    renderInput={(params) => <TextField {...params} label="Plan Name" variant="outlined" />}
                  />
                  <Button style={{
                width: "8rem",
                height: "2.5rem",
                marginLeft:'1rem'
              }}
              onClick={()=>{setShowFilter(false)}}>Cancel</Button>
                  <Button style={{
                width: "8rem",
                height: "2.5rem",
                marginLeft:'1rem'
              }}
              onClick={handleApplyFilter}>Apply Filter</Button>

              </div>
              :null
            } */}
            <TableWithExport
              title={
                job_status === "CLOSE"
                  ? "All On Campus Closed Jobs"
                  : "All On Campus Open Jobs"
              }
              data={onCampusData}
              columns={columns}
              fName={
                job_status === "CLOSE"
                  ? "Selected_On_Campus_Closed_Jobs"
                  : "Selected_On_Campus_Open_Jobs"
              }
              fromJobs={true}
            />
          </TabPanel>
        ) : (
          <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        )
      ) : null}

      {value === 1 ? (
        !loading ? (
          <TabPanel value={value} index={1} dir={theme.direction}>
            <br />

            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "5px",
              }}
            >
              <Button
                style={{
                  width: "8rem",
                  height: "2.5rem",
                  marginRight: "5rem",
                }}
                onClick={() => {
                  setShowFilter(true);
                }}
              >
                Filter
              </Button>

              {/* <DateSelector
                dateRange={[startDate, endDate]}
                applyDateFilter={applyDateFilter}
                removeDateFilter={removeDateFilter}
              /> */}

              <Button
                style={{
                  width: "12rem",
                  height: "2.5rem",
                }}
                onClick={() => {
                  setCanDownload(true);
                  downloadAllDataCSV();
                }}
              >
                {loadingAllDataDownload ? (
                  <CustomButtonCircularProgress />
                ) : (
                  "Download All Data"
                )}
              </Button>
            </div>
            <TableWithExport
              title={
                job_status === "CLOSE"
                  ? "All Off Campus Closed Jobs"
                  : "All Off Campus Open Jobs"
              }
              data={offCampusData}
              columns={columns}
              fName={
                job_status === "CLOSE"
                  ? "Selected_Off_Campus_Closed_Jobs"
                  : "Selected_Off_Campus_Open_Jobs"
              }
              fromJobs={true}
            />
          </TabPanel>
        ) : (
          <img src={gif} alt="Getwork Loading Gif..." style={gifStyling} />
        )
      ) : null}

      {/* Display Colleges Modal*/}
      {viewCollegeModal ? (
        !circularLoading ? (
          modalCollegesData.length > 0 ? (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Colleges</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>College Name</th>
                      <th>Apply Start Date</th>
                      <th>Apply End Date</th>
                      <th>Approved?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalCollegesData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m.college_name}</td>
                          <td>{m.apply_start_date}</td>
                          <td>{m.apply_end_date}</td>
                          <td>{m.is_approved ? "Yes" : "No"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          ) : (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Colleges</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span
                  style={{
                    display: "block",
                    marginLeft: "30%",
                    fontSize: "18px",
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  No Colleges Added !!!
                </span>
              </Modal.Body>
            </Modal>
          )
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Colleges</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CircularProgress style={{ display: "block", margin: "auto" }} />
            </Modal.Body>
          </Modal>
        )
      ) : null}

      {/* Assignments Modal*/}
      {viewAssignmentModal ? (
        !circularLoading ? (
          modalAssignmentsData.length > 0 ? (
            <Modal show={open} onHide={handleClose} scrollable={true} size="lg">
              <Modal.Header closeButton>
                <Modal.Title>Assignment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Description</th>
                      <th>URL</th>
                      <th>Deadline</th>
                      <th>File</th>
                    </tr>
                  </thead>
                  <tbody>
                    {modalAssignmentsData.map((m, key) => {
                      return (
                        <tr key={key}>
                          <td>{m.title}</td>
                          <td style={{ minWidth: "200px" }}>{m.description}</td>
                          <td>{m.url}</td>
                          <td>{m.end_date}</td>
                          {m.file ? (
                            <td>
                              <p
                                onClick={() => window.open(m.file)}
                                style={{
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                              >
                                View
                              </p>
                            </td>
                          ) : (
                            <td>
                              {" "}
                              <p> No File </p>{" "}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Modal.Body>
            </Modal>
          ) : (
            <Modal show={open} onHide={handleClose} scrollable={true}>
              <Modal.Header closeButton>
                <Modal.Title>Assignment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <span
                  style={{
                    display: "block",
                    marginLeft: "30%",
                    fontSize: "18px",
                    color: "red",
                    fontFamily: "cursive",
                  }}
                >
                  No Assignment Added !!!
                </span>
              </Modal.Body>
            </Modal>
          )
        ) : (
          <Modal show={open} onHide={handleClose} scrollable={true}>
            <Modal.Header closeButton>
              <Modal.Title>Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <CircularProgress style={{ display: "block", margin: "auto" }} />
            </Modal.Body>
          </Modal>
        )
      ) : null}

      {/* Assignments Modal*/}
      {uploadAssignmentModal ? (
        <Modal show={open} onHide={handleClose} scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title>Upload an Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="add__assignment__form"
              onSubmit={(e) => addAssignment(e, jobId)}
            >
              <Form.Row>
                <Form.Group as={Col} controlId="title">
                  <Form.Label>Assignment Title*</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Assignment Title goes here..."
                    required
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="deadline">
                  <Form.Label>Assignment Deadline*</Form.Label>
                  <Form.Control
                    name="deadline"
                    type="date"
                    placeholder="YYYY-MM-DD"
                    required
                    onChange={(e) => {
                      setDeadline(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form.Row>
              <Form.Group>
                <Autocomplete
                  multiple
                  filterSelectedOptions
                  id="select-candidate-status"
                  options={statusObj}
                  getOptionLabel={(item) => item.name}
                  onChange={(e, item) =>
                    item ? setStatusArr(item) : setStatusArr([])
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Candidate Status"
                      variant="outlined"
                    />
                  )}
                />
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Control
                  name="description"
                  as="textarea"
                  rows="2"
                  placeholder="Assignment Description goes here..."
                  required
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="url">
                <Form.Control
                  name="url"
                  type="text"
                  placeholder="Assignment URL (if applicable)"
                  onChange={(e) => {
                    setUrl(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.File
                id="formcheck-api-custom"
                custom
                style={{ marginBottom: "3%" }}
              >
                <Form.File.Input
                  onChange={(e) => setFile(e.target.files[0])}
                  isValid
                />
                <Form.File.Label data-browse="Upload File">
                  Click to choose file from local directory
                </Form.File.Label>
                {file ? (
                  <Form.Control.Feedback type="valid">
                    {file.name}
                  </Form.Control.Feedback>
                ) : null}
              </Form.File>
              <Button id="upload-assignment-btn" type="submit">
                Upload
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}

      {/* Extra Data Modal*/}
      {viewExtraDataModal ? (
        <Modal show={open} onHide={handleClose} scrollable={true}>
          <Modal.Header closeButton>
            <Modal.Title>Extra Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              className="add__assignment__form"
              onSubmit={(e) => editExtraData(e, jobId)}
            >
              <Form.Group controlId="jobStage">
                <Form.Label style={labelStyle}>Job Stage: </Form.Label>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setJobStage(e.target.value);
                  }}
                >
                  {jobStage ? (
                    <option value={jobStage}>--{jobStage}--</option>
                  ) : (
                    <option value="">--Select--</option>
                  )}
                  <option value="No Action">No Action</option>
                  <option value="Follow Up 1">Follow Up 1</option>
                  <option value="Follow Up 2">Follow Up 2</option>
                  <option value="Follow Up 3">Follow Up 3</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="followUp1">
                <Form.Label style={labelStyle}>Follow Up 1: </Form.Label>
                <Form.Control
                  name="followUp1"
                  type="text"
                  placeholder="Follow Up 1"
                  value={followUp1}
                  onChange={(e) => {
                    setFollowUp1(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="followUp2">
                <Form.Label style={labelStyle}>Follow Up 2: </Form.Label>
                <Form.Control
                  name="followUp2"
                  type="text"
                  placeholder="Follow Up 2"
                  value={followUp2}
                  onChange={(e) => {
                    setFollowUp2(e.target.value);
                  }}
                />
              </Form.Group>
              <Form.Group controlId="followUp3">
                <Form.Label style={labelStyle}>Follow Up 3: </Form.Label>
                <Form.Control
                  name="followUp3"
                  type="text"
                  placeholder="Follow Up 3"
                  value={followUp3}
                  onChange={(e) => {
                    setFollowUp3(e.target.value);
                  }}
                />
              </Form.Group>
              <Button
                style={{ display: "block", margin: "auto" }}
                type="submit"
              >
                Save Changes
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      ) : null}

      {/* Close Jobs Modal*/}
      {modalCloseJobData.job_id ? (
        <Modal
          show={open}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalCloseJobData.job_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Are you sure you want to close this job ?</p>
            <br />
            <Form>
              <Form.Label>
                Kindly enter the reason to close this job*
              </Form.Label>
              <Form.Group controlId="closeJobReason">
                <Form.Control
                  name="closeJobReason"
                  type="text"
                  placeholder="Reason to Close this job goes here..."
                  required
                  onChange={(e) => {
                    setCloseReason(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={(e) => closeParticularJob(modalCloseJobData.job_id)}
            >
              Close Job
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {openOfferLetter && (
        <ViewOfferLetter
          currentJobAndCompanyId={currentJobAndCompanyId}
          open={openOfferLetter}
          setOpen={setOpenOfferLetter}
          title={"Offer Letters"}
        />
      )}

      {shareJobProfile && (
        <ShareDialog
          currentSelectedRow={currentSelectedRow}
          open={shareJobProfile}
          setOpen={setShareJobProfile}
          title={"Share This Job"}
        />
      )}

      {showAddAdminUser && (
        <AddAdminUser
          open={showAddAdminUser}
          handleClose={() => {
            setShowAddAdminUser(false);
          }}
          selectedRowJobId={selectedRowJobId}
          preSelectedAdminUser={preSelectedAdminUser}
          setAddUserAdminClick={setAddUserAdminClick}
          selectedRecruiterList={selectedRecruiterList}
          setSelectedRecruiterList={setSelectedRecruiterList}
        />
      )}

      {showFilter && (
        <OpenCloseJobFilter
          open={showFilter}
          handleClose={() => {
            setShowFilter(false);
          }}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </div>
  );
}

export default OpenCloseJobs;
